import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import config from 'config';
// import { parseStyleProps, } from '@haaretz/htz-css-tools';
import Image from '../Image/Image';
import H from '../AutoLevels/H';

import HtmlNode from '../HtmlNode/HtmlNode';

// TODO: remove exclusion when this feature is added to HDC
const shouldDisplayBio = config.has('domain')
  && typeof config.get('domain') === 'string'
  && !config.get('domain').includes('haaretz.com');

const authorImageMiscStyles = {
  width: '100%',
  height: 0,
  paddingBottom: '100%',
  borderRadius: '50%',
  overflow: 'hidden',
  display: [ { until: 's', value: 'inline-block', }, { from: 'l', value: 'block', }, ],
  // marginInlineEnd: '2rem',
};

const imgOption = {
  transforms: {
    width: '70',
    aspect: 'square',
  },
};

function AuthorBio({ author, sectionName, }) {
  const { theme, css, } = useFela();
  if (!(author && author.biography?.length)) return null;

  return shouldDisplayBio ? (
    <div
      className={css({
        marginTop: '14rem',
        marginBottom: '14rem',
        // extend: [ ...parseStyleProps(parseLayout(magazineLayout), theme.mq, theme.type), ],
      })}
    >
      <div
        className={css({
          backgroundColor: theme.color('authorBio', 'infoBg'),
          // paddingLeft: '3rem',
          // paddingRight: '3rem',
          transform: 'skewY(-4deg)',
          transformOrigin: 'top left',
        })}
      >
        <div
          className={css({
            paddingBottom: '5rem',
            paddingLeft: '3rem',
            paddingRight: '3rem',
            paddingTop: '2rem',
            backgroundColor: theme.color('authorBio', 'infoBg'),
            transform: 'skewY(4deg)',
            transformOrigin: 'top left',
          })}
        >
          {author.image
            ? (
              <div
                className={css({
                  backgroundColor: theme.color('authorBio', 'imageBg'),
                  borderRadius: '50%',
                  width: '10rem',
                  height: '10rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: theme.color('neutral', '-1'),
                  position: 'absolute',
                  top: '-9rem',
                  extend: [
                    theme.mq({ until: 's', }, { top: '-12rem', }),
                    theme.mq({ from: 's', until: 'm', }, { top: '-12rem', }),
                    theme.mq({ from: 'm', until: 'l', }, { top: '-14rem', }),
                    theme.mq({ from: 'l', }, { top: '-11rem', }),
                    theme.mq({ until: 'xl', }, { width: '12rem', height: '12rem', }),
                  ],
                })}
              >
                <Image
                  image={author.image}
                  imgOptions={imgOption}
                  miscStyles={authorImageMiscStyles}
                />
              </div>
            ) : null}
          <H className={css({ color: 'white', extend: [ theme.type(1), ], })}>
            <span>{author.contentName}</span>
            {sectionName
              ? (
                <span className={css({ fontWeight: 300, })}>
                  {' | '}
                  {sectionName}
                </span>
              ) : null }
          </H>
          {Array.isArray(author.biography) ? author.biography.map(item => (
            <HtmlNode
              {...item}
              miscStyles={{
                marginTop: '1rem',
                color: `${theme.color('neutral', '-10')}`,
                type: [ { value: 0, lines: 4, }, ],
                '& a': {
                  color: theme.color('biography', 'link'),
                  borderBottomColor: theme.color('biography', 'link'),
                  ':hover': {
                    color: theme.color('biography', 'linkHover'),
                  },
                  ':visited': {
                    color: theme.color('biography', 'linkVisited'),
                  },
                  ':focus': {
                    color: theme.color('biography', 'linkFocus'),
                  },
                  ':active': {
                    color: theme.color('biography', 'linkActive'),
                  },
                },
              }}
            />
          )) : null}
        </div>
      </div>
    </div>
  ) : null;
}

AuthorBio.propTypes = {
  author: PropTypes.shape({
    contentName: PropTypes.string,
    image: PropTypes.string,
    biography: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  sectionName: PropTypes.string,
};

AuthorBio.defaultProps = {
  sectionName: null,
};

export default AuthorBio;
