// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import Button from '../Button/Button';

type Props = {
  sectionUrl: string,
};

BackToLabelSection.defaultProps = {
  sectionUrl: '/labels',
};

function BackToLabelSection({ sectionUrl, }: Props) {
  const { css, theme, } = useFela();

  return (
    <div
      className={css({
        maxWidth: theme.articleStyle.body.maxWidth,
        marginRight: 'auto',
        marginLeft: 'auto',
      })}
    >
      <Button
        miscStyles={{
          backgroundColor: 'transparent',
          marginInlineEnd: '2rem',
          fontWeight: 'bold',
          marginTop: '6rem',
        }}
        href={sectionUrl}
      >
        {theme.labelsI18n.backToSection}
      </Button>
    </div>
  );
}
export default BackToLabelSection;
