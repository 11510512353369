/* global fetch, Headers */
import React from 'react';
import { useFela, } from 'react-fela';
import { parseTypographyProp, parseStyleProps, } from '@haaretz/htz-css-tools';
import PropTypes from 'prop-types';

import HeaderText from '../../../ArticleHeader/HeaderText';
import ArticleHeaderMeta from '../../../ArticleHeader/ArticleHeaderMeta';
import HeadlineElement from '../../../HeadlineElement/HeadlineElement';
import Breadcrumbs from '../../../Breadcrumbs/Breadcrumbs';
import ShareBar from '../../../ShareBar/ShareBar';
import useWebViewChecker from '../../../../hooks/useWebViewChecker';
import useShouldDisplayOpeningElement from '../../useShouldDisplayOpeiningElement';

Header.propTypes = {
  /**
   * An array of Article's authors.
   */
  authors: PropTypes.arrayOf(PropTypes.oneOfType([ PropTypes.string, PropTypes.object, ])).isRequired,
  canonicalUrl: PropTypes.string.isRequired,
  shareUrl: PropTypes.string,
  hasBreadCrumbs: PropTypes.bool.isRequired,
  includeExclusiveInTitle: PropTypes.bool,
  exclusive: PropTypes.string.isRequired,
  mobileExclusive: PropTypes.string.isRequired,
  /**
   * The publishing date of the article
   */
  pubDate: PropTypes.instanceOf(Date).isRequired,
  /**
   * The modification date of the article
   */
  modDate: PropTypes.instanceOf(Date),
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  headlineElement: PropTypes.shape({}).isRequired,
  reportingFrom: PropTypes.string,
  isLabel: PropTypes.bool,
  showDate: PropTypes.bool,
};

Header.defaultProps = {
  modDate: null,
  reportingFrom: null,
  includeExclusiveInTitle: null,
  isLabel: false,
  showDate: true,
  shareUrl: null,
};

const headerStyle = ({ theme, }) => ({
  textAlign: 'start',
  backgroundColor: theme.color('bg', 'base'),
  extend: [ theme.mq({ until: 'm', }, { display: 'flex', flexDirection: 'column', }), ],
});

const headerTextStyle = ({ theme, isWebView, }) => ({
  color: theme.color('articleHeader', 'titleText'),
  marginTop: '3rem',
  paddingInlineStart: '2rem',
  extend: [
    theme.mq(
      { until: 's', },
      {
        paddingInlineEnd: '3rem',
        paddingInlineStart: '3rem',
        marginTop: isWebView ? '3rem' : '0',
      }
    ),
    theme.mq({ from: 's', until: 'l', }, { paddingInlineEnd: '2rem', }),
    theme.mq({ from: 'xl', }, { paddingInlineStart: '3rem', }),
  ],
});

const breadcrumbsStyle = ({ theme, }) => ({
  paddingInlineStart: theme.layoutStyle.startColumnPadding,
  paddingTop: '3rem',
  extend: [
    theme.mq({ until: 's', }, { paddingInlineStart: theme.layoutStyle.contPaddingS, }),
    theme.mq(
      { from: 'xl', },
      {
        paddingInlineStart: theme.layoutStyle.startColumnPaddingXL,
      }
    ),
    theme.mq({ until: 's', }, { order: -1, marginBottom: '1rem', }),
    ...parseStyleProps(theme.articleStyle.header.breadcrumbs.margins, theme.mq, theme.type),
    parseTypographyProp(theme.articleStyle.header.breadcrumbs.type, theme.type),
  ],
});

const galleryImgOptions = theme => ({
  bps: theme.bps,
  aspect: 'headline',
  sizes: [
    { from: 'xl', size: '604px', },
    { from: 'l', size: '556px', },
    { from: 'm', size: '720px', },
    { from: 's', size: '552px', },
    { size: '100wv', },
  ],
  widths: [ 375, 469, 556, 604, 720, ],
});

const defaultImgOptions = (aspect, isFullScreen) => ({
  sizes: `${
    isFullScreen
      ? '100vw'
      : '(min-width:1280px) 840px, (min-width:1024px) 652px, (min-width:768px) 768px,(min-width:600px) 600px, 100vw'
  }`,
  transforms: [
    {
      width: '350',
      aspect,
      quality: 'auto',
    },
    {
      width: '450',
      aspect,
      quality: 'auto',
    },
    {
      width: '600',
      aspect,
      quality: 'auto',
    },

    {
      width: '652',
      aspect,
      quality: 'auto',
    },
    {
      width: '768',
      aspect,
      quality: 'auto',
    },
    {
      width: '840',
      aspect,
      quality: 'auto',
    },

    {
      width: '1024',
      aspect,
      quality: 'auto',
    },
    {
      width: '1280',
      aspect,
      quality: 'auto',
    },
    {
      width: '1420',
      aspect,
      quality: 'auto',
    },
    {
      width: '1920',
      aspect,
      quality: 'auto',
    },
  ],
});

function Header({
  hasBreadCrumbs,
  canonicalUrl,
  shareUrl,
  authors,
  exclusive,
  includeExclusiveInTitle,
  mobileExclusive,
  modDate,
  pubDate,
  subtitle,
  title,
  headlineElement,
  reportingFrom,
  isLabel,
  showDate,
}) {
  const isWebView = useWebViewChecker();
  const { css, theme, } = useFela({ isWebView, });

  const showOpeningElement = useShouldDisplayOpeningElement(headlineElement);

  return (
    <header className={css(headerStyle)}>
      {hasBreadCrumbs ? <Breadcrumbs className={css(breadcrumbsStyle)} /> : null}
      <HeaderText
        className={css(headerTextStyle)}
        exclusive={exclusive}
        includeExclusiveInTitle={includeExclusiveInTitle}
        mobileExclusive={exclusive}
        subtitle={subtitle}
        title={title}
      />
      <ArticleHeaderMeta
        authors={authors}
        isLabel={isLabel}
        publishDate={pubDate}
        modifiedDate={modDate}
        reportingFrom={reportingFrom}
        showDate={showDate}
        miscStyles={{
          marginTop: [
            { until: 's', value: theme.articleStyle.header.metaTopMarginMobile || '3rem', },
            { from: 's', until: 'l', value: '2rem', },
          ],
          marginBottom: [
            { until: 's', value: theme.articleStyle.header.metaBottomMarginMobile, },
          ],
          marginInlineStart: [
            {
              from: 's',
              value: theme.layoutStyle.startColumnPadding,
            },
            { until: 's', value: theme.layoutStyle.contPaddingS, },
          ],
          marginInlineEnd: [
            {
              from: 's',
              value: theme.layoutStyle.startColumnPadding,
            },
            { until: 's', value: theme.layoutStyle.contPaddingS, },
          ],
          display: [ { until: 'l', value: 'block', }, { from: 'l', value: 'none', }, ],
        }}
      />
      <div
        className={css({
          paddingInlineStart: '2rem',
          extend: [
            theme.mq({ until: 'l', }, { paddingInlineEnd: '2rem', }),
            theme.mq({ from: 'xl', }, { paddingInlineStart: '3rem', }),
          ],
        })}
      >
        <ShareBar
          title={title}
          canonicalUrl={shareUrl || canonicalUrl}
          isLabel={isLabel}
        />
      </div>
      {headlineElement && showOpeningElement ? (
        <HeadlineElement
          elementObj={headlineElement}
          imgOptions={headlineElement.kind === 'Gallery' ? galleryImgOptions : defaultImgOptions}
          miscStyles={{
            marginTop: [ { from: 's', value: '2rem', }, ],
            order: [ { until: 's', value: -2, }, ],
          }}
          captionMiscStyles={theme.articleStyle.header.headlineElementCaptionMiscStyles}
        />
      ) : null}
    </header>
  );
}

export default Header;
