import React from 'react';
import { FelaComponent, } from 'react-fela';
import { parseComponentProp, } from '@haaretz/htz-css-tools';
import PropTypes from 'prop-types';
import SectionTitleA from '../SectionTitleA/SectionTitleA';
import Section from '../AutoLevels/Section';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

const margineliaStyle = ({ theme, theme: { layoutStyle, }, hideUnderLargeBreakPoint, }) => ({
  extend: [
    ...(hideUnderLargeBreakPoint ? [ theme.mq({ until: 'l', }, { display: 'none', }), ] : []),
    theme.mq(
      { from: 'l', },
      {
        position: 'absolute',
      }
    ),
    theme.mq(
      { from: 'l', until: 'xl', },
      {
        start: `${layoutStyle.startColumnPadding}rem`,
        maxWidth: `${layoutStyle.startColumnWidthL - layoutStyle.startColumnPadding}rem`,
      }
    ),
    theme.mq(
      { from: 'xl', },
      {
        start: `${layoutStyle.startColumnPaddingXL}rem`,
        maxWidth: `${layoutStyle.startColumnWidthXL - layoutStyle.startColumnPaddingXL}rem`,
      }
    ),
  ],
});

const ArticleLayoutRow = ({
  bgc,
  children,
  id,
  isArticleBody,
  title,
  margineliaComponent,
  hideMargineliaComponentUnderLBp,
  paddingTop,
  elementRef,
  miscStyles,
}) => (
  <div ref={elementRef}>
    <FelaComponent
      style={({ theme, }) => ({
        ...(bgc ? { backgroundColor: bgc, } : {}),
        extend: [
          parseComponentProp(
            'paddingTop',
            paddingTop,
            theme.mq,
          ),
         ...(miscStyles
              ? parseStyleProps(miscStyles, theme.mq, theme.type)
        : [])
        ],
      })}
    >
      {({ className, }) => (
        <Section className={className} data-test="articleLayoutRow">
          {title ? (
            <SectionTitleA isInMargin={!!(id === 'commentsSection')} title={title} id={id || null} />
          ) : null}
          <FelaComponent
            style={({ theme, }) => ({
              position: 'relative',
              extend: [
                theme.mq(
                  { from: 'l', until: 'xl', },
                  { paddingInlineStart: `${theme.layoutStyle.startColumnWidthL}rem`, }
                ),
                theme.mq(
                  { from: 'xl', },
                  { paddingInlineStart: `${theme.layoutStyle.startColumnWidthXL}rem`, }
                ),
              ],
            })}
          >
            <FelaComponent
              style={margineliaStyle}
              hideUnderLargeBreakPoint={hideMargineliaComponentUnderLBp}
            >
              {margineliaComponent || null}
            </FelaComponent>

            <FelaComponent
              style={({ theme, }) => ({
                extend: [
                  theme.mq({ until: 's', }, { paddingInlineStart: '3rem', paddingInlineEnd: '3rem', }),
                  theme.mq(
                    { from: 's', until: 'l', },
                    { paddingInlineStart: '2rem', paddingInlineEnd: '2rem', }
                  ),
                  theme.mq({ from: 'l', }, { paddingInlineStart: '5rem', }),
                ],
              })}
            >
              {children}
            </FelaComponent>
          </FelaComponent>
        </Section>
      )}
    </FelaComponent>
  </div>
);

const mqType = PropTypes.oneOf([ 's', 'm', 'l', 'xl', ]);

ArticleLayoutRow.propTypes = {
  bgc: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  id: PropTypes.string,
  isArticleBody: PropTypes.bool,
  title: PropTypes.string,
  margineliaComponent: PropTypes.arrayOf(PropTypes.element),
  hideMargineliaComponentUnderLBp: PropTypes.bool,
  elementRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object, }),
  ]),
  paddingTop: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.shape({
        from: mqType,
        until: mqType,
        value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, ]),
      }),
    ),
  ]),
};

ArticleLayoutRow.defaultProps = {
  bgc: null,
  children: null,
  id: null,
  isArticleBody: false,
  title: null,
  margineliaComponent: null,
  elementRef: null,
  hideMargineliaComponentUnderLBp: true,
  paddingTop: [
    { until: 'xl', value: '3rem', },
    { from: 'xl', value: '4rem', },
  ],
};

export default ArticleLayoutRow;
