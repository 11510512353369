// @flow
import React, { Fragment, } from 'react';
import { useFela, } from 'react-fela';
// import PropTypes from 'prop-types';
import ApolloConsumer from '../../ApolloBoundary/ApolloConsumer';
import WideArticleLayoutRow from '../../PageLayout/WideArticleLayoutRow';
import ArticleLayoutRow from '../../PageLayout/ArticleLayoutRow';
import useGetComponent from '../../../hooks/GetComponentContext/useGetComponent';
import ArticleBody from '../../ArticleBody/ArticleBody';
import ArticleHeaderMeta from '../../ArticleHeader/ArticleHeaderMeta';
import ArticleAside from '../../ArticleAside/ArticleAside';
import PreviewExclude from '../../PreviewExclude/PreviewExclude';
import BackToLabelSection from '../../BackToLabelSection/BackToLabelSection';
import StandardArticleHeader from './StandardArticleElements/StandardArticleHeader';
import AuthorBio from '../../AuthorBio/AuthorBio';
import { isList, } from '../../../utils/validateType';
import useArticleBodyData from '../../../hooks/Page/useArticleBodyData';
import type { ArticleContentType, } from '../../../flowTypes/articleContentType';
import useWebViewChecker from '../../../hooks/useWebViewChecker';
import useHeaderArticleData from '../../../hooks/Page/useArticleHeaderData';
import useAuthorsData from '../../../hooks/Page/useAuthorsData';
import useArticleData from '../../../hooks/Page/useArticleData';
import useSlotData from '../../../hooks/Page/useSlotData';
import useBreadcrumbsData from '../../../hooks/Page/useBreadcrumbsData';
import useArticleId from '../../../hooks/Page/useArticleId';
import useBlogElement from '../../../hooks/Page/useBlogElement';
import useIsLabel from '../../../hooks/Page/useIsLabel';
import usePrint from '../../../hooks/Page/usePrint';
import ArticleObserver from '../../Article/ArticleObserver';

function StandardArticle({
  path,
  site,
  canonicalUrl,
  shareUrl,
  showTrinityPlayer,
}: ArticleContentType) {
  const breadcrumbs = useBreadcrumbsData();
  const getComponent = useGetComponent();
  const { theme, css, } = useFela();
  const isWebView = useWebViewChecker();

  // const { article, aside, } = slots;
  const body = useArticleBodyData();

  const articleId = useArticleId();
  const data = useArticleData();
  const { isBlog, blog, } = useBlogElement();
  const isLabel = useIsLabel();
  const { isPrint, } = usePrint();
  const articleBodyRef = React.useRef(null);

  const article = data || {};
  const lineage = breadcrumbs || [];

  const { commentsElementId, openingElement, } = article;

  const authors = useAuthorsData();

  // const { article, aside, } = slots;

  // const breadCrumbs = article.find(element => element.inputTemplate === 'com.tm.PageTitle');

  // const isMouse = articleData.inputTemplate === 'com.mouse.story.MouseStandardStory';
  const isMouse = false;

  // const {
  //   authors,
  //   body,
  //   headlineElement,
  //   tags,
  //   header,
  //   commentsElementId,
  //   enableComments,
  //   tldr,
  //   tldrWordCount,
  // } = articleData;

  const enableComments = !!commentsElementId;

  const header = useHeaderArticleData();
  const main = useSlotData('main');

  const showDate = (header || {}).showDate == null ? true : header?.showDate;

  const dontDisplayComments = (Array.isArray(authors)
    && authors.filter(author => author.contentId && author.contentId === '1.758').length)
    || isLabel
    || enableComments === false;


  let authorBio;
  if (isBlog && authors?.length === 1) {
    const author = authors[0];

    authorBio = <AuthorBio author={author} sectionName={blog} />;
  }


  const labelsSections = [ '2.16463', '2.16464', '2.16455', '2.16457', '2.16465', ];
  let mouseDisclaimer = null;

  if (labelsSections.includes(lineage?.[1]?.contentId)) {
    const disclaimerStyle = {
      marginBottom: '3rem',
      paddingTop: '3rem',
      extend: [
        theme.type(-2, { untilBp: 'xl', lines: 3, }),
        theme.type(-2, { fromBp: 'xl', lines: 3, }),
      ],
    };

    mouseDisclaimer = (
      <div className={css(disclaimerStyle)}>
        * התוכן נכתב על ידי מחלקת שיתופי הפעולה של הארץ/עכבר העיר, עבור עיריית
        {` ${lineage[1].name}`}
      </div>
    );
  }

  const mainSlot = React.useMemo(() => ((main || []).map(element => {
    if (isWebView && (element.contentName || '').includes('article.textlink')) {
      return null;
    }
    if (
      element.inputTemplate === 'com.htz.ArticleHeaderElement'
      || element.inputTemplate === 'com.tm.PageTitle'
    ) {
      return null;
    }


    const isMichelle = isList(element)
      ? element.view === 'Michelle'
      : false;

    // eslint-disable-next-line no-underscore-dangle
    const Element = getComponent(element.kind || element.inputTemplate, {
      ...element,
      loadPriority: element.loadPriority,
      isExpanded: element.isExpanded,
      preventRender: element.preventRender,
    });

    const { properties, ...elementWithoutProperties } = element;

    if (
      element.inputTemplate === 'Outbrain'
      || element.inputTemplate === 'com.polobase.ClickTrackerBannersWrapper'
      || isMichelle
    ) {
      return (
        <WideArticleLayoutRow
          key={element.contentId}
          hideDivider
          {...(element.inputTemplate === 'com.polobase.ClickTrackerBannersWrapper'
            ? {
              miscStyles: {
                display: [ { until: 's', value: 'none', }, ],
              },
            }
            : {})}
        >
          <Element
            articleId={articleId}
            {...(isMichelle
              ? {
                viewProps: { isLabel, },
              }
              : {})}
            {...elementWithoutProperties}
            {...properties}
          />
        </WideArticleLayoutRow>
      );
    }
    // TODO: check why isComment check is only for standard article
    const isComments = element.inputTemplate === 'CommentsPlaceHolder';
    return dontDisplayComments && isComments ? null : (
      <ArticleLayoutRow
        key={element.contentId}
        {...(isComments
          ? {
            title: theme.articleLayoutI18n.commentSectionTitle,
            id: 'commentsSection',
          }
          : {})}
      >
        <Element articleId={articleId} {...elementWithoutProperties} {...properties} />
      </ArticleLayoutRow>
    );
  })), [ articleId, dontDisplayComments, getComponent, isLabel, isWebView, main, theme.articleLayoutI18n.commentSectionTitle, ]);


  return (
    <>
      <div
        className={css({
          extend: [
            theme.mq({ from: 'l', }, { width: isPrint ? 'calc(100% - 300px - 8rem)' : undefined, }),
            theme.mq({ from: 'l', until: 'xl', }, { paddingInlineEnd: '3rem', }),
            theme.mq({ from: 'xl', }, { paddingEnd: '10rem', }),
          ],
        })}
      >
        <StandardArticleHeader
          {...header}
          articleId={articleId}
          authors={authors}
          isLabel={isLabel}
          canonicalUrl={canonicalUrl}
          shareUrl={shareUrl}
          hasBreadCrumbs={!!lineage}
          headlineElement={openingElement}
          showDate={showDate}
        />

        <ApolloConsumer>
          {cache => {
            cache.writeData({
              data: {
                commentsElementId,
                isMouseStory: isMouse,
              },
            });

            return (
              <ArticleLayoutRow
                isArticleBody
                hideMargineliaComponentUnderLBp={!!authors}
                elementRef={articleBodyRef}
                miscStyles={{
                  minHeight: [ { from: 'l', value: '25rem', }, ],
                }}
                margineliaComponent={(
                  <Fragment>
                    {authors ? (
                      <ArticleHeaderMeta
                        authors={authors}
                        reportingFrom={header?.reportingFrom}
                        publishDate={header?.datePublishedISO}
                        modifiedDate={header?.dateModifiedISO}
                        isLabel={isLabel}
                        showDate={showDate}
                      />
                    ) : null}
                  </Fragment>
                      )}
              >
                <ArticleBody
                  body={body || []}
                  path={path}
                  site={site}
                  showTrinityPlayer={showTrinityPlayer}
                />
                {mouseDisclaimer}
                {authorBio}
                {isLabel ? <BackToLabelSection sectionUrl={(lineage[1] || {}).url} /> : null}
                <ArticleObserver
                  observerRef={articleBodyRef}
        // The breakpoint values indicate the point at which the user has scrolled through the article,
        // and the updateFunction property specifies whether or not a specific function
        // (such as an update mutation) should be triggered when the corresponding breakpoint is reached.
        // In this example, the updateFunction will only be triggered
        // when the user reaches the breakpoint of 0.4 while scrolling through the article.
                  breakpoints={[
                    { breakpoint: 0.2, },
                    { breakpoint: 0.4, shouldUpdate: true, },
                    { breakpoint: 0.6, },
                    { breakpoint: 0.8, },
                    { breakpoint: 1, },
                  ]}
                />
              </ArticleLayoutRow>
            );
          }}
        </ApolloConsumer>

        <PreviewExclude>
          {mainSlot}
        </PreviewExclude>
      </div>
      <ArticleAside />
    </>
  );
}

StandardArticle.defaultProps = {
  isBlock: false,
};

export default StandardArticle;
